import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import clsx from 'clsx'

import text from 'text.json'
import './index.scss'
import ContentLoader from 'components/core/loaders/ContentLoader'
import ButtonLoader from 'components/core/button/ButtonLoader'
import Button from 'components/core/button'
import {SubscriptionState, ErrorResponse} from 'utils/types'
import StatuspageService from 'api/statuspage'
import {setIsSubscriptionProcessing} from 'stores/subscription'
import {AppDispatch} from 'stores/index'
import {routes} from 'utils/routes'
import {TickIcon, CrossIcon} from 'icons/index'

const unsubscribeServicesList = [
    'Status page updates'
]

const actionTypes = {
    verify: 'verify',
    unsubscribe: 'unsubscribe'
}

const Subscription: React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const {id} = useParams<{id: string}>()
    const queryParams = new URLSearchParams(window.location.search)
    const action = queryParams.get('action')
    const [response, setResponse] = useState({
        error: false,
        message: ''
    })
    const [unsubscribedMessage, setUnsubscribedMessage] = useState('')
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
    const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false)
    const tabletBreakPoint = 768
    const isSubscriptionProcessing = useSelector((state: SubscriptionState) => state.subscription.isSubscriptionProcessing)

    const verifySubscription = async() => {
        if (!id) {
            return
        }
        dispatch(setIsSubscriptionProcessing(true))
        try {
            await StatuspageService.verifySubscription(id)
            setResponse({
                error: false,
                message: text.subscription_verified_message
            })
        } catch (e) {
            const errorResponse = e as ErrorResponse
            const res = errorResponse.response?.data
            const message = res?.message || text.verify_error_message
            setResponse({
                error: true,
                message: message
            })
        }
        dispatch(setIsSubscriptionProcessing(false))
    }

    const deleteSubscription = async() => {
        if (!id) {
            return
        }
        dispatch(setIsSubscriptionProcessing(true))
        try {
            const {data} = await StatuspageService.deleteSubscription(id)
            setUnsubscribedMessage(data?.message)
        } catch (e) {
            setUnsubscribedMessage('')
            const errorResponse = e as ErrorResponse
            const res = errorResponse.response?.data
            const message = res?.message
            toast.error(message)
        }
        dispatch(setIsSubscriptionProcessing(false))
    }

    useEffect(() => {
        if (action === actionTypes.verify) {
            verifySubscription()
        }
    }, [id])

    useEffect(() => {
        if (windowWidth < tabletBreakPoint) {
            setIsSmallDevice(true)
            return
        }
        setIsSmallDevice(false)
    }, [windowWidth])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
    
        window.addEventListener('resize', handleResize)
    
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <main className="subscription">
            <div className="content">
                
                {
                    action === actionTypes.verify ?
                        isSubscriptionProcessing ?
                            <ContentLoader /> :
                            <div className="verify">
                                <div className={clsx('icon', {
                                    ['error']: response.error
                                })}>
                                    {!response.error ? <TickIcon width={isSmallDevice ? 24 : 36} /> : <CrossIcon width={isSmallDevice ? 24 : 36} />}
                                </div>
                                <h3 dangerouslySetInnerHTML={{__html: response.message}} />
                            </div> :
                        <div className="unsubscribe">
                            {
                                unsubscribedMessage ?
                                    <div className="unsubscribed-message">
                                        <div className="icon">
                                            <TickIcon width={isSmallDevice ? 24 : 36} />
                                        </div>
                                        <h4>{unsubscribedMessage}</h4>
                                    </div> :
                                    <>
                                        <h3>{text.are_you_sure_unsubscribe}</h3>
                                        <ul>
                                            <p>{text.you_will_unsubscribe}</p>
                                            {unsubscribeServicesList.map((service, index) => {
                                                return (
                                                    <li key={index}>{service}</li>
                                                )
                                            })}
                                        </ul>
                                        <div className="buttons">
                                            <Button
                                                type='button'
                                            >
                                                <Link to={routes.home.path} className="btn">
                                                    <span>{text.cancel}</span>
                                                </Link>
                                            </Button>
                                            <Button
                                                type='button'
                                                isDisabled={isSubscriptionProcessing}    
                                                onClick={deleteSubscription}
                                            >
                                                {isSubscriptionProcessing && <ButtonLoader classname='pink' />}
                                                <span>{text.unsubscribe}</span>
                                            </Button>
                                        </div>
                                    </>
                            }
                        </div>
                }
            </div>
        </main>
    )
}
export default Subscription
