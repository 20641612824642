import React from 'react'

import {TickIcon} from 'icons/index'

const EmptyIcon: React.FC = () => {
    return (
        <div className="empty-icon">
            <TickIcon />
        </div>
    )
}

export default EmptyIcon
