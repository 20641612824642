import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'

import text from 'text.json'
import './index.scss'
import ContentLoader from 'components/core/loaders/ContentLoader'
import {
    ArrowLeftIcon,
    IncidentHistoryIcon,
    PlusIcon,
    IncidentIcon,
    TickIcon
} from 'icons/index'
import StatuspageService from 'api/statuspage'
import {routes} from 'utils/routes'
import DataNotFound from 'components/core/empty/DataNotFound'
import {HeaderState} from 'utils/types'
import EmptyIcon from './EmptyIcon'
import ThemeManager from 'utils/themeManager'
import {doNothing} from 'utils/placeholder'

interface HistoryData {
    date:    string
    changes: Change[]
}

interface Change {
    incident_id: string
    status:      string
    started_at:  string
    ended_at:    string | null
    name:        string
    description: string
}

const IncidentHistories: React.FC = () => {
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const [historyData, setHistoryData] = useState<HistoryData[]>([])
    const [isFetching, setIsFetching] = useState<boolean>(true)
    const [incidentCountToDisplay, setIncidentCountToDisplay] = useState<number>(3)
    const [itemIndexToDisplay, setItemIndexToDisplay] = useState<number>()
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')

    const calculateDates = () => {
        const currentDate = new Date()
        currentDate.setHours(23, 59, 59, 0)

        const startDateObj = new Date(currentDate)
        startDateObj.setMonth(currentDate.getMonth() - 1)
        startDateObj.setHours(0, 0, 0, 0)

        const endDateStr = currentDate.toISOString()
        const startDateStr = startDateObj.toISOString()

        setStartDate(startDateStr)
        setEndDate(endDateStr)
    }

    const incrementMonth = () => {
        const startDateObj = new Date(startDate)
        const endDateObj = new Date(endDate)

        startDateObj.setMonth(startDateObj.getMonth() + 1)
        endDateObj.setMonth(endDateObj.getMonth() + 1)

        setStartDate(startDateObj.toISOString())
        setEndDate(endDateObj.toISOString())
    }

    const decrementMonth = () => {
        const startDateObj = new Date(startDate)
        const endDateObj = new Date(endDate)

        startDateObj.setMonth(startDateObj.getMonth() - 1)
        endDateObj.setMonth(endDateObj.getMonth() - 1)

        setStartDate(startDateObj.toISOString())
        setEndDate(endDateObj.toISOString())
    }

    const isDateLessThanCurrentYearMonth = (dateString: string): boolean => {
        const currentDate = new Date()
        const targetDate = new Date(dateString)
      
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth()
        const targetYear = targetDate.getFullYear()
        const targetMonth = targetDate.getMonth()
      
        return targetYear < currentYear || (targetYear === currentYear && targetMonth < currentMonth)
    }

    const query = `?start_date=${startDate}&end_date=${endDate}`

    const getIncidentHistoryData = async() => {
        setIsFetching(true)
        try {
            const {data} = await StatuspageService.getStatuspageHistories(query)
            setHistoryData(data?.data?.history.sort((a: HistoryData, b: HistoryData) => {
                const dateA = new Date(a.date)
                const dateB = new Date(b.date)
              
                return dateB.getTime() - dateA.getTime()
            }))
        } catch (e) {
            doNothing()
        }
        setIsFetching(false)
    }

    useEffect(() => {
        calculateDates()
    }, [])

    useEffect(() => {
        startDate && endDate && getIncidentHistoryData()
    }, [startDate, endDate])

    return (
        <main className="histories">
            <div className="head">
                <div className="head-img">
                    <IncidentHistoryIcon />
                </div>
                <div className="head-header">
                    <h1>{text.incident_history}</h1>
                </div>
                <div className="head-description">
                    <button className="switch-button" onClick={decrementMonth}><ArrowLeftIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} /></button>
                    <p>{moment(startDate).format('MMMM YYYY')} to {moment(endDate).format('MMMM YYYY')}</p>
                    <button className="switch-button" disabled={!isDateLessThanCurrentYearMonth(endDate)} onClick={incrementMonth}><ArrowLeftIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} /></button>
                </div>
            </div>
            <div className="histories-container">
                {
                    isFetching ? <ContentLoader /> : historyData?.length > 0 ? (
                        <>
                            {historyData?.map((history: HistoryData, index: number) => {
                                return (
                                    <div key={index}>
                                        <div className="histories-container-content">
                                            <h2>{moment(history?.date).format('MMMM YYYY')}</h2>
                                            <div className="incidents">
                                                {history?.changes?.slice(0, itemIndexToDisplay === index ? incidentCountToDisplay : 3)?.map((change: Change, changeIndex: number) => {
                                                    return (
                                                        <div key={changeIndex + change?.incident_id} className={clsx('incident', {
                                                            ['resolved']: change?.status === 'resolved',
                                                            ['open']: 'open'
                                                        })}>
                                                            <span className="incident-logo">
                                                                {change?.status === 'resolved' ? <TickIcon stroke="#00c9bd" width={18} height={18} /> : <IncidentIcon width={22} height={18} />}
                                                            </span>
                                                            <div className="incident-description">
                                                                <h5>{change?.status}</h5>
                                                                <Link to={routes.incident_inner.path.replace(':id', change?.incident_id)}>
                                                                    {change?.name}
                                                                </Link>
                                                                <h3>{change?.description}</h3>
                                                                <span>{moment(change?.started_at).format("MMM D, HH:mm")}{change?.ended_at && ` - ${moment(change?.ended_at).format("MMM D, HH:mm")}`} UTC</span>
                                                            </div>
                                                        </div>                                                        
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        {
                                            history?.changes?.length > 3 && itemIndexToDisplay !== index && (
                                                <button onClick={() => {
                                                    setIncidentCountToDisplay(history?.changes?.length)
                                                    setItemIndexToDisplay(index)
                                                }}>
                                                    <span>{text.show_more.replace(':value', `${history?.changes?.length}`)}</span>
                                                    <PlusIcon stroke={ThemeManager.getIconProperStroke(isDarkMode )} />
                                                </button>
                                            )
                                        }
                                    </div>
                                )
                            })}
                        </>
                    ) : <DataNotFound
                        icon={EmptyIcon}
                        iconProps={{
                            width: 120,
                            height: 120,
                        }}
                        text={text.not_recorded}
                    />
                }
            </div>
        </main>
    )
}
export default IncidentHistories
