import React, {useRef} from 'react'
import Modal from 'react-modal'
import {useSelector} from 'react-redux'
import clsx from 'clsx'

import './index.scss'
import useOnClickOutside from 'utils/hooks/useOutsideClick'
import {CloseIcon} from 'icons'
import {HeaderState} from 'utils/types'

interface CustomModalProps {
    title: string
    children: React.ReactNode
    isOpen: boolean
    closeModal: () => void
}

const CustomModal: React.FC<CustomModalProps> = ({
    title,
    children,
    isOpen,
    closeModal
}) => {
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => closeModal())

    return (
        <Modal
            overlayClassName={clsx({
                'dark': isDarkMode
            })}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            isOpen={isOpen}
            onRequestClose={closeModal}
        >   
            <div className="modal-container" ref={ref}>
                <div className="modal-header">
                    <span className="title">
                        {title}
                    </span>
                    <span
                        className="close-icon"
                        onClick={closeModal}>
                        <CloseIcon stroke={isDarkMode ? 'white' : ''} />
                    </span>
                </div>
                {children}
            </div>
        </Modal>
    )
}

export default CustomModal
