import {toast} from 'react-toastify'

type Fields = Record<string, string[] | undefined>

const setFormElementError = (
    fields: Fields | null | undefined,
    message?: string,
    setFieldError?: (field: string, errorMessage: string) => void
): void => {
    if (!fields) {
        toast.error(message || 'Error')
        return
    }

    const fieldsArr = Object.keys(fields)

    if (fieldsArr.includes('common')) {
        toast.error(fields.common?.[0] || message || 'Error')
        return
    }

    if (typeof setFieldError === 'function') {
        fieldsArr.forEach(item => {
            setFieldError(item, fields[item]?.join('<br>') || '')
        })
        return
    }

    fieldsArr.forEach(item => {
        toast.error(fields[item]?.[0] || '')
    })
}

export default {
    setFormElementError
}
