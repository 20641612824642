import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {useParams, Link} from 'react-router-dom'
import {useSelector} from 'react-redux'

import text from 'text.json'
import './index.scss'
import FullScreenLoader from 'components/core/loaders/FullScreenLoader'
import {
    TickIcon,
    CrossIcon,
    ArrowLeftIcon
} from 'icons/index'
import {HeaderState} from 'utils/types'
import StatuspageService from 'api/statuspage'
import {routes} from 'utils/routes'
import DataNotFound from 'components/core/empty/DataNotFound'
import EmptyIcon from './EmptyIcon'
import ThemeManager from 'utils/themeManager'
import {doNothing} from 'utils/placeholder'

interface HistoryData {
    history:   Component[];
    component: Component;
}

interface Component {
    id?:         string;
    status:      string;
    name:        string;
    description: string;
    date?:       string;
}

const initialHistoryData: HistoryData = {
    history: [],
    component: {
        status: '',
        name: '',
        description: ''
    },
}

const IncidentInner: React.FC = () => {
    const {id} = useParams<{id: string}>()
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const [historyData, setHistoryData] = useState(initialHistoryData)
    const [isFetching, setIsFetching] = useState<boolean>(true)
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
    const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false)
    const tabletBreakPoint = 768
    const resolvedType = 'resolved'

    const getIncidentHistoryData = async(id?: string) => {
        setIsFetching(true)
        try {
            const {data} = await StatuspageService.getIncidentHistory(id)
            setHistoryData(data?.data)
        } catch (e) {
            doNothing()
        }
        setIsFetching(false)
    }

    useEffect(() => {
        getIncidentHistoryData(id)
    }, [id])

    useEffect(() => {
        if (windowWidth < tabletBreakPoint) {
            setIsSmallDevice(true)
            return
        }
        setIsSmallDevice(false)
    }, [windowWidth])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
    
        window.addEventListener('resize', handleResize)
    
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return isFetching ?
        <FullScreenLoader /> : (
            <main className="history-inner">
                <div className="content">
                    {historyData?.history?.length > 0 ? <>
                        <div className="content-title">
                            <div className={clsx('content-title-img', {
                                ['incident']: historyData?.history?.[0]?.status !== resolvedType
                            })}>
                                {historyData?.history?.[0]?.status === resolvedType ? <TickIcon width={isSmallDevice ? 24 : 36} /> : <CrossIcon width={isSmallDevice ? 24 : 36} />}
                            </div>
                            <h1>{historyData?.component?.name}</h1>
                        </div>
                        {historyData?.history?.map((item: Component, index: number) => {
                            return (
                                <div key={index} className="content-item">
                                    <h4 className="item-title">{item?.status}</h4>
                                    <p className="item-name">{item?.name}</p>
                                    <p className="item-desc">{item?.description}</p>
                                    <p className="item-date">{moment(item?.date).format('MMMM D, YYYY · HH:mm [GMT]')}</p>
                                </div>                            
                            )
                        })}
                    </> : <DataNotFound
                        icon={EmptyIcon}
                        iconProps={{
                            width: 120,
                            height: 120,
                        }}
                        text={text.not_recorded}
                    />
                    }                    
                </div>
                <div className="buttons">
                    <Link to={routes.incident_histories.path} className="btn">
                        <ArrowLeftIcon stroke={ThemeManager.getIconProperStroke(isDarkMode)} />
                        <span>{text.back}</span>
                    </Link>
                </div>
            </main>
        )
}
export default IncidentInner
