import React, {useState, useEffect} from 'react'
import {Formik, FormikHelpers} from 'formik'

import './index.scss'
import text from 'text.json'
import {NotificationIcon} from 'icons/index'
import CustomModal from 'components/core/modal'
import Input from 'components/core/input'
import Button from 'components/core/button'
import ButtonLoader from 'components/core/button/ButtonLoader'
import {subscriptionSchema} from 'utils/validation'
import FormHelper from 'utils/forms'
import StatuspageApiService from 'api/statuspage'
import {ErrorResponse} from 'utils/types'

interface SubscribeProps {
    name: string
}

interface FormValues {
    channel_value: string
}
  
const Subscribe: React.FC<SubscribeProps> = ({name}) => {
    const [isModalActive, setIsModalActive] = useState(false)
    const [showButtonLoader, setShowButtonLoader] = useState(false)
    const [showConfirmMessage, setShowConfirmMessage] = useState(false)
    const initialFormValues: FormValues = {channel_value: ''}
    
    const submitForm = async (
        values: FormValues,
        {setFieldError, resetForm}: FormikHelpers<FormValues>
    ) => {
        const requestPayload = {
            channel_type: 'email',
            channel_value: values.channel_value
        }
        setShowButtonLoader(true)
        try {
            await StatuspageApiService.addSubscription(requestPayload)
            setShowConfirmMessage(true)
            resetForm()
        } catch (e) {
            const errorResponse = e as ErrorResponse
            const res = errorResponse.response?.data
            const fields = res?.data?.fields
            const message = res?.message
            FormHelper.setFormElementError(fields, message, setFieldError)
        }
        setShowButtonLoader(false)
    }

    const handleShowModal = () => {
        setIsModalActive((prev) => !prev)
    }

    useEffect(() => {   
        if (isModalActive) {
            document.body.style.overflow = 'hidden'
            return
        }
        document.body.style.overflow = 'unset'
    }, [isModalActive])
    
    return (
        <div className="subscribe">
            <button
                type="button"
                onClick={handleShowModal}
            >
                <NotificationIcon />
                <span>{text.subscribe}</span>
            </button>
            <CustomModal
                title={text.subscription}
                closeModal={() => {
                    setIsModalActive(false)
                    setShowConfirmMessage(false)
                }}
                isOpen={isModalActive}
            >
                <div className="modal-content">
                    <div className="description" dangerouslySetInnerHTML={{__html: text.subscription_desc.replace(':pagename', name)}}/>
                    <Formik<FormValues>
                        onSubmit={submitForm}
                        validationSchema={subscriptionSchema}
                        initialValues={initialFormValues}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {({values, errors, handleChange, handleSubmit}) => (
                            <form onSubmit={handleSubmit} className="form-elements">
                                <Input
                                    name="channel_value"
                                    type="text"
                                    placeholder={text.email}
                                    value={values.channel_value}
                                    error={errors.channel_value}
                                    onChange={e => handleChange(e)}
                                />
                                {showConfirmMessage && (
                                    <div className="info-box">
                                        {text.subscription_confirm_message}
                                    </div>
                                )}
                                <div className="buttons">
                                    <Button
                                        classname="light-button"
                                        type="submit"
                                        onClick={handleShowModal}
                                    >
                                        {text.cancel}
                                    </Button>
                                    <Button
                                        type="submit"
                                        isDisabled={showButtonLoader}
                                    >
                                        {showButtonLoader && <ButtonLoader/>}
                                        {text.submit}
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </CustomModal>
        </div>
    )
}

export default Subscribe
