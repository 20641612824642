import React from 'react'
import './index.scss'
import {CheckIcon, ErrorIcon} from '../../../icons'
import {ToastContainer, ToastOptions} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Alert: React.FC = () => {
    const alertDuration = 3000

    const toastOptions: ToastOptions = {
        position: 'top-center',
        autoClose: alertDuration
    }

    return (
        <ToastContainer
            {...toastOptions}
            icon={({type}) => {
                return type === 'success' ? <CheckIcon /> : <ErrorIcon />
            }}
        />
    )
}

export default Alert
