const darkModeIconStroke = '#FFF'
const lightModeIconStroke = '#3C4F65'

const ThemeManager = {
    getIconProperStroke: (isDarkMode: boolean) => {
        return isDarkMode ? darkModeIconStroke : lightModeIconStroke
    }
}

export default ThemeManager
