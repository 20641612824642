import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import {routeArr} from 'utils/routes'
import MainLayout from 'components/layouts/mainLayout/MainLayout'

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {routeArr.map((item, index) => (
                    <Route index path={item.path} key={index} element={
                        <MainLayout item={item} />
                    }
                    />
                ))}
            </Routes>
        </BrowserRouter>
    )
}

export default App
