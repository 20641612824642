import React from 'react'

import './index.scss'
import Spinner from 'assets/images/spinner.svg'

const ContentLoader: React.FC = () => {
    return (
        <div className="content-loader">
            <img className="spinner" width="auto" height="auto" src={Spinner} alt="Pinghome"/>
        </div>
    )
}

export default ContentLoader
