interface Text {
    required: (field: string, isMulti?: boolean) => string
    like_email: (field: string) => string
}

const text: Text = {
    required: (field, isMulti = false) => `${field} ${isMulti ? 'are' : 'is'} required`,
    like_email: field => `${field} must match to email format`
}

export default text
