import React from 'react'

import './index.scss'
import text from 'text.json'
import {PageNotFound} from 'icons/index'

const NotFoundPage: React.FC = () => {
    return (
        <main className="not-found">
            <div className="not-found-container">
                <span className="page-icon">
                    <PageNotFound />
                </span>
                <span className="not-found-text">
                    {text.not_found}
                </span>
            </div>
        </main>
    )
}

export default NotFoundPage
