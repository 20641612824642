const CalculationService = {
    calculateAverage: (numbersAsString: (string | null | undefined)[]): string | null => {
        const numbers = numbersAsString.map(item => {
            if (!item) {
                return null
            }
            const num = parseFloat(item)
            return isNaN(num) ? null : num
        }).filter(num => num !== null) as number[]
    
        if (numbers.length === 0) {
            return null
        }
    
        const sum = numbers.reduce((acc, num) => acc + num, 0)
        const average = sum / numbers.length
    
        return `${average.toFixed(2)}%`
    }
}

export default CalculationService
