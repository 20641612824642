import {createSlice} from '@reduxjs/toolkit'

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        isSubscriptionProcessing: false
    },
    reducers: {
        setIsSubscriptionProcessing: (state, {payload}) => {
            state.isSubscriptionProcessing = payload
        }
    }
})

export const {
    setIsSubscriptionProcessing
} = subscriptionSlice.actions

export default subscriptionSlice.reducer
