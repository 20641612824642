import * as Yup from 'yup'
import text from './validationText'

const fields = {
    EMAIL: 'Email'
}

const subscriptionSchema = Yup.object().shape({
    channel_value: Yup.string()
        .required(text.required(fields.EMAIL))
        .email(text.like_email(fields.EMAIL))
})

export {
    subscriptionSchema
}
