import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import clsx from 'clsx'

import './index.scss'
import data from './data.json'
import ModeSwitcher from 'components/core/switcher/ModeSwitcher'
import Subscribe from 'components/layouts/subscribe/Subscribe'
import LocationHelper from 'utils/locationHelper'
import {CloseIcon, MenuHomeIcon, MenuIncidentsIcon} from 'icons/index'
import {HeaderState} from 'utils/types'
import {getAssetsUrl} from "utils/envManager"

interface HeaderProps {
    name: string
    websiteUrl: string
    logo: string
    isSubscriptionEnabled: boolean
}

interface MenuIcons {
    [key: string]: React.ReactNode;
}

const Header: React.FC<HeaderProps> = (props) => {
    const {name, logo, isSubscriptionEnabled} = props
    const location = useLocation()
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)
    const currentLocation = new LocationHelper(location)
    const pathname = currentLocation.isHome() ? 'status' : location?.pathname

    const menuIcons: MenuIcons = {
        status: <MenuHomeIcon stroke={isDarkMode ? 'white' : ''} />,
        incident: <MenuIncidentsIcon stroke={isDarkMode ? 'white' : ''} />
    }

    const closeMobileMenu = () => setIsMobileMenuActive(false)

    useEffect(() => {
        if (isMobileMenuActive) {
            document.body.style.overflow = 'hidden'
            return
        }
        document.body.style.overflow = 'unset'
    }, [isMobileMenuActive])

    return (
        <div className="top-content">
            <div className="bg"></div>
            <header>
                <div className="header">
                    <Link to="/" className="logo">
                        {logo && <img className="logo-img" src={getAssetsUrl(true) + logo} alt={name || ''} />}
                        {!logo && <span>{name || ''}</span>}
                    </Link>
                    <nav>
                        <ul>
                            {data?.nav_items?.map(item => {
                                return (
                                    <li className={clsx({
                                        ['active']: pathname?.includes(item?.key)
                                    })} key={item?.key}>
                                        <Link to={item?.path}>{item?.name}</Link>
                                    </li>
                                )
                            })}
                            <li className="actions">
                                <ModeSwitcher />
                                {isSubscriptionEnabled &&
                                    <Subscribe name={name} />
                                }
                            </li>
                        </ul>
                    </nav>
                    <div className={clsx('mobile-menu', {
                        'active': isMobileMenuActive
                    })}>
                        <span className="menu-toggler" onClick={() => setIsMobileMenuActive(true)}>
                            <span />
                            <span />
                            <span />
                        </span>
                        <div className="menu-content">
                            <div className="menu">
                                <span className="circle" />
                                <span className="circle" />
                                <span className="circle" />
                                <div className="top">
                                    <ModeSwitcher />
                                    <span onClick={closeMobileMenu} className="closer">
                                        <CloseIcon stroke={isDarkMode ? 'white' : ''} />
                                    </span>
                                </div>
                                <nav className="mobile-navbar">
                                    <ul>
                                        {data?.nav_items?.map(item => {
                                            return (
                                                <li className={clsx({
                                                    ['active']: pathname?.includes(item?.key)
                                                })} key={item?.key}>
                                                    <Link onClick={closeMobileMenu} to={item?.path}>
                                                        {menuIcons[item?.key]}
                                                        {item?.name}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </nav>
                                {isSubscriptionEnabled &&
                                    <div className="w-100 subscribe-container" onClick={closeMobileMenu}>
                                        <Subscribe name={name} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header
