import React from 'react'

import './index.scss'
import clsx from 'clsx'

interface PulseProps {
    classname: string
}
const Pulse: React.FC<PulseProps> = ({classname}) => {
    return (
        <span className={clsx('pulse', {
            [classname]: classname
        })}/>
    )
}

export default Pulse
