import React from 'react'
import StatusPage from 'pages/home'
import NotFoundPage from 'pages/not-found'
import IncidentInner from 'pages/incident-history/IncidentInner'
import IncidentHistories from 'pages/incident-history'
import Subscription from 'pages/subscription'

type Route = {
  path: string
  component: React.ComponentType
}

const routeItem = (path: string, component: React.ComponentType): Route => {
    return {
        path,
        component,
    }
}

const routes: Record<string, Route> = {
    home: routeItem('/', StatusPage),
    incident_histories: routeItem('/incident/histories', IncidentHistories),
    incident_inner: routeItem('/incident/:id', IncidentInner),
    subscription: routeItem('/subscription/:id', Subscription),
    not_found: routeItem('/not-found', NotFoundPage),
    unhandled_route: routeItem('/*', NotFoundPage),
}

const routeArr = Object.values(routes)

export {routes, routeArr}
