const developmentEnvironmentFlag = 'dev'

const isNewFeatureEnabled = () => {
    return process.env.REACT_APP_NEW_FEATURE_ENABLED === 'true'
}

const isDevOriginEnabled = () => {
    return process.env.REACT_APP_DEV_ORIGIN_ENABLED === 'true'
}

const isDevEnv = () => {
    return process.env.REACT_APP_ENV === developmentEnvironmentFlag
}

const getAssetsUrl = (isCustomAsset: boolean) => {
    return isCustomAsset ? process.env.REACT_APP_PINGHOME_CUSTOMER_MEDIA_URL : process.env.PUBLIC_URL
}

export {
    isNewFeatureEnabled,
    isDevOriginEnabled,
    isDevEnv,
    getAssetsUrl,
}
