import {statuspageQueryService, statuspageCmdService} from '../index'

interface SubscriptionProps {
    channel_type: string
    channel_value: string
}
class Statuspage {
    getStatuspage = () => statuspageQueryService.get('statuspage')
    getComponentStatistics = (id: string) => statuspageQueryService.get(`component/${id}/statistics`)
    getIncidentHistory = (id?: string) => statuspageQueryService.get(`incident/${id}/history`)
    getStatuspageHistories = (query: string) => statuspageQueryService.get(`statuspage/history${query}`)
    addSubscription = (data: SubscriptionProps) => statuspageCmdService.post('subscriptions', data)
    verifySubscription = (token: string) => statuspageCmdService.put(`subscription/${token}/verification`)
    deleteSubscription = (token: string) => statuspageCmdService.delete(`subscription/${token}`)
}

export default new Statuspage()
