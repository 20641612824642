import React from 'react'
import clsx from 'clsx'

import './index.scss'

interface ButtonLoaderProps {
    classname?: string
}

const ButtonLoader: React.FC<ButtonLoaderProps> = ({classname}) => {
    return (
        <div className={clsx('loader', {
            [classname || '']: classname
        })} />
    )
}

export default ButtonLoader
