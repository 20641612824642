import React from 'react'
import {useSelector} from 'react-redux'
import clsx from 'clsx'

import './index.scss'
import text from 'text.json'
import PinghomeLogoDark from 'assets/images/pinghome-logo-dark.svg'
import PinghomeLogoLight from 'assets/images/pinghome-logo-light.svg'
import {HeaderState} from 'utils/types'

interface FooterProps {
    isBrandingRemoved: boolean | undefined
    pageName: string
    privacyUrl: string
    supportUrl: string
}

interface NavItem {
    url: string
    name: string
}
  
const Footer: React.FC<FooterProps> = ({isBrandingRemoved, pageName, privacyUrl, supportUrl}) => {
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const navItems = [
        {
            url: privacyUrl,
            name: text.privacy_policy
        },
        {
            url: supportUrl,
            name: text.help_center
        }
    ]
    return (
        <footer>
            <div className={clsx('footer', {
                ['justify-content-center']: (!privacyUrl && !supportUrl) || (isBrandingRemoved && !pageName),
            })}>
                {
                    (privacyUrl || supportUrl) &&
                        <nav>
                            <ul>
                                {navItems?.map((item: NavItem, index: number) => {
                                    return item?.url &&
                                        <li key={index}>
                                            <a href={item?.url} target="_blank" rel="noreferrer">{item?.name}</a>
                                        </li>
                                })}
                            </ul>
                        </nav>
                }
                {!isBrandingRemoved ? <div className="powered-by">
                    <span>{text.powered_by}</span>
                    <a href={process.env.REACT_APP_PINGHOME_URL} target="_blank" rel="noreferrer">
                        <img src={isDarkMode ? PinghomeLogoLight : PinghomeLogoDark} alt="Pinghome" />
                    </a>
                </div> : pageName && <div className="page-name">
                    <h1>
                        <span>©</span>
                        {pageName}
                    </h1>
                </div>}
            </div>
        </footer>
    )
}

export default Footer
