import {createSlice} from '@reduxjs/toolkit'

import LocalStorageManager from 'utils/localStorageManager'
import localStorageKeys from 'utils/localStorageKeys'

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        isDarkMode: LocalStorageManager.get(localStorageKeys.IS_DARK_MODE)
    },
    reducers: {
        setIsDarkMode: (state, {payload}) => {
            state.isDarkMode = payload
        }
    }
})

export const {
    setIsDarkMode
} = headerSlice.actions

export default headerSlice.reducer
