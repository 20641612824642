import React, {ReactNode} from 'react'
import {useSelector} from 'react-redux'
import clsx from 'clsx'

import './index.scss'
import {HeaderState} from 'utils/types'

interface ButtonProps {
    children: ReactNode
    onClick?: () => void
    type?: 'button' | 'submit' | 'reset' | undefined
    isDisabled?: boolean
    classname?: string
    id?: string
}

const Button: React.FC<ButtonProps> = ({
    children,
    onClick,
    type,
    isDisabled,
    classname,
    id
}) => {
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    return (
        <button
            type={type}
            onClick={onClick}
            id={id}
            disabled={isDisabled}
            className={clsx('button', classname, {
                'dark': isDarkMode
            })}
        >
            {children}
        </button>
    )
}

export default Button
