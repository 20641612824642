import {createSlice} from '@reduxjs/toolkit'
import {ThunkAction} from 'redux-thunk'
import {AnyAction} from 'redux'
import {NavigateFunction} from 'react-router-dom'

import StatuspageService from 'api/statuspage'
import {RootState} from '..'
import {routes} from 'utils/routes'
import moment from 'moment'

export const statuspageSlice = createSlice({
    name: 'statuspage',
    initialState: {
        statuspageData: {},
        isFetching: false,
        updatedDate: ''
    },
    reducers: {
        setStatuspageData: (state, {payload}) => {
            state.statuspageData = payload
        },
        setIsFetching: (state, {payload}) => {
            state.isFetching = payload
        },
        setUpdatedDate: (state, {payload}) => {
            state.updatedDate = payload
        }
    }
})

export const {
    setStatuspageData,
    setIsFetching,
    setUpdatedDate
} = statuspageSlice.actions

export default statuspageSlice.reducer

export const getStatuspage = (navigate: NavigateFunction, showLoader: boolean, isAutoRefresh: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        showLoader && dispatch(setIsFetching(true))
        try {
            const {data} = await StatuspageService.getStatuspage()
            dispatch(setStatuspageData(data?.data))
            const currentDate = new Date()
            dispatch(setUpdatedDate(moment(currentDate).format('MMM D [at] hh:mma [CEST]')))
        } catch (e) {
            if (isAutoRefresh) {
                return
            }
            navigate(routes.not_found.path)
        }
        dispatch(setIsFetching(false))
    }
}
