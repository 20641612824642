import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux/es/exports'
import ReactGA from 'react-ga4'
import {NavigateFunction, useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {Helmet, HelmetProvider} from 'react-helmet-async'

import data from './data.json'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Alert from 'components/core/alert'
import {AppDispatch} from 'stores/index'
import {getStatuspage} from 'stores/statuspage'
import {HeaderState, StatuspageState, SubscriptionState} from 'utils/types'
import LocalStorageManager from 'utils/localStorageManager'
import localStorageKeys from 'utils/localStorageKeys'
import {setIsDarkMode} from 'stores/header'
import {getAssetsUrl} from "utils/envManager"

interface RouteItem {
    path: string
    component: React.ComponentType
}

interface MainLayoutProps {
    item: RouteItem
}

const MainLayout: React.FC<MainLayoutProps> = ({item}) => {
    const dispatch: AppDispatch = useDispatch()
    const navigate: NavigateFunction = useNavigate()

    const statuspageData = useSelector((state: StatuspageState) => state.statuspage.statuspageData)
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const isSubscriptionProcessing = useSelector((state: SubscriptionState) => state.subscription.isSubscriptionProcessing)
    const defaultSeoValues = data.default_seo_data

    const favIconSize16x16 = "16x16"
    const favIconSize32x32 = "32x32"
    const favIconSize96x96 = "96x96"
    const favIcon = statuspageData.common?.favicon

    const getFaviconUrl = (sizes: string, favicon?: string) => favicon ? getAssetsUrl(true) + favicon : getAssetsUrl(false) + `/favicons/favicon-${sizes}.png`

    useEffect(() => {
        if (!Object.values(statuspageData)?.length && !isSubscriptionProcessing) {
            dispatch(getStatuspage(navigate, true, false))
        }
    }, [isSubscriptionProcessing])

    useEffect(() => {
        statuspageData?.common?.ga_tracking && ReactGA.initialize(statuspageData?.common?.ga_tracking)
        if (!LocalStorageManager.get(localStorageKeys.IS_DEFAULT_THEME_DISABLED)) {
            LocalStorageManager.set(localStorageKeys.IS_DARK_MODE, !statuspageData?.theme?.dark)
            dispatch(setIsDarkMode(statuspageData?.theme?.dark))
        }
    }, [statuspageData])

    return (
        <HelmetProvider>
            <div className={clsx('container', {
                ['dark']: isDarkMode
            })}>
                <Helmet>
                    <link rel="icon" href={getFaviconUrl(favIconSize16x16, favIcon)} sizes={favIconSize16x16}/>
                    <link rel="icon" href={getFaviconUrl(favIconSize32x32, favIcon)} sizes={favIconSize32x32}/>
                    <link rel="icon" href={getFaviconUrl(favIconSize96x96, favIcon)} sizes={favIconSize96x96}/>
                </Helmet>
                <Helmet prioritizeSeoTags>
                    <title>{statuspageData?.common?.name || defaultSeoValues?.page_title}</title>
                    <meta content={statuspageData?.common?.description || defaultSeoValues?.page_title} name="description" />
                    <meta content={statuspageData?.common?.name || defaultSeoValues?.page_title} name="keywords" />
                    <meta content={statuspageData?.common?.name || defaultSeoValues?.page_title} property="og:title" />
                    <meta content={statuspageData?.common?.name || defaultSeoValues?.page_title} property="og:description" />
                    <meta content={statuspageData?.common?.website_url || process.env.REACT_APP_PINGHOME_URL} name="canonical" />
                    <meta content={statuspageData?.common?.website_url || process.env.REACT_APP_PINGHOME_URL} property="og:url" />
                </Helmet>
                <Alert/>
                <Header
                    name={statuspageData?.common?.name}
                    websiteUrl={statuspageData?.common?.website_url}
                    logo={statuspageData?.common?.logo}
                    isSubscriptionEnabled={statuspageData?.common?.subscription_enabled}
                />
                <item.component/>
                <Footer
                    isBrandingRemoved={statuspageData?.common?.remove_branding}
                    pageName={statuspageData?.common?.page_name}
                    privacyUrl={statuspageData?.common?.privacy_url}
                    supportUrl={statuspageData?.common?.support_url}
                />
            </div>
        </HelmetProvider>
    )
}

export default MainLayout
