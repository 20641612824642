import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useSelector, useDispatch} from 'react-redux'

import './index.scss'
import {setIsDarkMode} from 'stores/header'
import {AppDispatch} from 'stores/index'
import {HeaderState} from 'utils/types'
import {MoonIcon} from 'icons/index'
import LocalStorageManager from 'utils/localStorageManager'
import localStorageKeys from 'utils/localStorageKeys'

const ModeSwitcher: React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
    const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false)
    const tabletBreakPoint = 768

    const handleClick = () => {
        dispatch(setIsDarkMode(!isDarkMode))
        LocalStorageManager.set(localStorageKeys.IS_DEFAULT_THEME_DISABLED, true)
    }

    useEffect(() => {
        LocalStorageManager.set(localStorageKeys.IS_DARK_MODE, isDarkMode)
    }, [isDarkMode])

    useEffect(() => {
        if (windowWidth < tabletBreakPoint) {
            setIsSmallDevice(true)
            return
        }
        setIsSmallDevice(false)
    }, [windowWidth])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
    
        window.addEventListener('resize', handleResize)
    
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])    

    return (
        <div className="mode-switcher">
            <div
                className={clsx('switcher', {
                    ['active']: isDarkMode
                })}
                onClick={handleClick}
            />
            <MoonIcon stroke={((isDarkMode && !isSmallDevice) || (!isDarkMode && isSmallDevice)) ? '#00C9BD' : ''} />
        </div>
    )
}

export default ModeSwitcher
