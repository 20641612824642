interface LocationData {
    pathname: string
}

class LocationHelper {
    location: LocationData

    constructor(location: LocationData) {
        this.location = location
    }

    isHome() {
        return this.location.pathname === '/'
    }
}

export default LocationHelper
