import {configureStore} from '@reduxjs/toolkit'

import statuspageReducer from './statuspage'
import headerReducer from './header'
import subscriptionReducer from './subscription'

const store = configureStore({
    reducer: {
        statuspage: statuspageReducer,
        header: headerReducer,
        subscription: subscriptionReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
