import axios, {AxiosRequestConfig, AxiosInstance} from 'axios'
import {isDevEnv, isDevOriginEnabled} from "utils/envManager"

interface CustomHeaders {
    [key: string]: string
}

function createAxiosInstance(baseURL: string, envOrigin: string | undefined): AxiosInstance {
    const config: AxiosRequestConfig = {
        baseURL,
        headers: {} as CustomHeaders
    }

    if (isDevOriginEnabled() && isDevEnv() && envOrigin && config.headers) {
        config.headers['dev-origin'] = envOrigin
    }

    return axios.create(config)
}

const instances: AxiosInstance[] = [
    createAxiosInstance(process.env.REACT_APP_STATUSPAGE_QUERY_API_URL || '', process.env.REACT_APP_DEV_ORIGIN),
    createAxiosInstance(process.env.REACT_APP_STATUSPAGE_CMD_API_URL || '', process.env.REACT_APP_DEV_ORIGIN)
]

export const [
    statuspageQueryService,
    statuspageCmdService
] = instances
