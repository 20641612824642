import React, {useEffect, useRef, useState, FC, ChangeEvent, FocusEvent} from 'react'
import {useSelector} from 'react-redux'
import clsx from 'clsx'

import './index.scss'
import {FormErrorIcon} from 'icons/index'
import {HeaderState} from 'utils/types'

interface InputProps {
    type?: string
    name?: string
    placeholder?: string
    value?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void
    onFocus?: () => void
    defaultValue?: string
    innerRef?: React.MutableRefObject<HTMLInputElement | null>
    error?: string
    placeholderAsLabel?: boolean
    inputClassName?: string
}

const Input: FC<InputProps> = ({
    type,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    defaultValue,
    innerRef,
    error,
    placeholderAsLabel = true,
    inputClassName
}) => {
    const [hasValue, setHasValue] = useState<boolean>(false)
    const ref = useRef<HTMLInputElement | null>(null)
    const isDarkMode = useSelector((state: HeaderState) => state.header.isDarkMode)

    const placeholderClassName = hasValue && placeholder ? 'has-value' : 'empty'

    const updateLabelVisibility = () => {
        if (placeholderAsLabel) {
            const inputText = ref?.current?.value
            const condition = inputText?.length
            setHasValue(!!condition)
        }
    }

    useEffect(() => {
        updateLabelVisibility()
    }, [value])

    return (
        <div className={clsx('form-element', {
            'dark': isDarkMode
        })}>
            <div className="input">
                <input onChange={onChange} value={value} onBlur={onBlur} onFocus={onFocus}
                    className={clsx('simple-input', {
                        [placeholderClassName]: placeholderAsLabel,
                        error: error,
                        [inputClassName || '']: !!inputClassName,
                    })}
                    type={type}
                    name={name} id={name}
                    onInput={updateLabelVisibility}
                    placeholder={!placeholderAsLabel ? placeholder : undefined}
                    ref={placeholderAsLabel ? ref : innerRef}
                    defaultValue={defaultValue}
                />

                {placeholderAsLabel && (
                    <span
                        className={clsx('label', {
                            'input-placeholder': true,
                            [placeholderClassName || '']: !!placeholderClassName,
                            error: error
                        })}
                    >
                        {placeholder}
                    </span>
                )}
            </div>
            {error && (
                <div className="error">
                    <FormErrorIcon />
                    <span className="error-text">
                        {error}
                    </span>
                </div>
            )}
        </div>
    )
}

export default Input
