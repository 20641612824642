const LocalStorageManager = {
    set: (name: string, value: boolean) => {
        localStorage.setItem(name, JSON.stringify(value))
    },
    get: (name: string): boolean | null => {
        const storedValue = localStorage.getItem(name)
        if (storedValue !== null && storedValue !== 'undefined') {
            return JSON.parse(storedValue)
        }
        return null
    }
}

export default LocalStorageManager
